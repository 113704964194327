.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: white;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-bg: grey;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.img-hover {
  transition: all 0.5s;
}

.img-hover:hover {
  transform: scale(1.1);
}

#skills-list {
  .img-hover:hover {
    background-color: rgba(199, 199, 199, 0.651);
  }
}

.young-serif-regular {
  font-family: 'Young Serif', serif;
  font-weight: 400;
  font-style: normal;
}

.about-section {
  background-size: cover;
  background-position: center;
}

#site-hero {
  font-weight: 800;
  background-image: url(./assets/banner_image.jpg);
  height: 80vh;
  h1 {
    font-size: 46px;
  }
}

#about {
  background-image: url(./assets/AboutBackground.JPG);
}

#location {
  background-image: url(./assets/London.JPG);
}

#site-description {
  background-image: url('./assets/SiteDescription.JPG');
}

.translucent {
  opacity: 0.5;
}

.project-skill-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-auto-flow: column;
}

.skill-img {
  width: 50px;
  height: 50px;
}

.about-copy-container {
  background-color: rgba(199, 199, 199, 0.651);
  margin-top: 20rem;
  margin-bottom: 20rem;
}

@media (max-width: 750px) {
  .skill-img {
    width: 25px;
    height: 25px;
  }
  #site-hero {
    height: auto;
  }
}
